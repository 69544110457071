import React from "react"
import Thread from 'src/components/threads/Thread'

const ThreadGallery = (props) => {
  const threads = props.data
  return (
    <div>
      {threads?.map((item, index) => (
        <Thread data={item} key={index} />
      ))}
    </div>
  )
}

export default ThreadGallery