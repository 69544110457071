import React, { useState, useEffect, useMemo } from "react"
import { useLocation, navigate, Link } from "@reach/router";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Breadcrumb from "src/components/Breadcrumb";
import ThreadGallery from "src/components/threads/ThreadGallery";
import Pagination from "src/components/Pagination";
import { Container, Col, Row } from "react-bootstrap";
import { graphql } from "gatsby";
import axios from 'axios';

const Gallery = ({ data }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(searchParams.get('page'), 10) || 1;

  const threadsPerPage = 10; // Set to your desired number of images per page
  const [isLoading, setIsLoading] = useState(false);
  const [threadData, setThreadData] = useState([]);
  const [page, setPage] = useState(pageFromUrl);

  const getThreads = async (currentPage) => {
    let apiUrl = `${process.env.GATSBY_API_URL}thread/?pagesize=${threadsPerPage}&page=${currentPage}`;

    try {
      setIsLoading(true);
      const response = await axios.get(apiUrl);
      setThreadData(response.data);
    } catch (error) {
      console.error("Failed to fetch images:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getThreads(page);
    setPage(pageFromUrl);
  }, [pageFromUrl, page]);

  const handlePageChange = (newPage) => {
    window.location.href = `/threads/?page=${newPage}`;
    window.scrollTo(0, 0);
  };

  const generateTitle = () => {
    let title = `Threads`;
    return title;
  };

  const metadataTitle = "Threads";
  const metadataDescription = "All of the threads";
  const metadataImg = ""; // Set this if you have a specific image for SEO

  return (
    <Layout>
      <SEO title={metadataTitle} description={metadataDescription} imageURL={metadataImg} />
      <Container>
        <Row>
            <Col xs={12}>
              <Breadcrumb />
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <>
                  <h1 className="mb-4" dangerouslySetInnerHTML={{ __html: generateTitle() }}></h1>
                  <hr />
                  <ThreadGallery data={threadData.items} />
                </>
              )}
            </Col>
        </Row>
        <Row>
          {!isLoading && (
            <Col xs={12} className="text-center">
              <Pagination 
                  currentPage={threadData.currentPage} 
                  totalPages={threadData.totalPages} 
                  onPageChange={handlePageChange}
              />
            </Col>
          )}
        </Row>
      </Container>
    </Layout>
  );
};

export default Gallery;

export const query = graphql`
    query ImageQuery {
      wpPage(title: {eq: "IMAGES"}) {
        metadata {
          metadataTitle
          metadataImage {
            localFile {
              url
            }
          }
          metadataDescription
        }
        title
        content
      }
    }
`;
