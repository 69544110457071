import React, { useState, useRef, useEffect } from "react"
import Image from 'src/components/images/Image'

const Thread = (props) => {
    const item = props.data

    const images = [item.ImageOne.id, item.ImageTwo.id, item.ImageThree.id]
    const [hoveredImages, setHoveredImages] = useState([])

    const mapTagsWithIds = (tags, imageId) => {
        return tags.map(tag => ({ tagName: tag.tagName, imageId }));
    };

    const tagsOne = mapTagsWithIds(item.ImageOne.tags, item.ImageOne.id);
    const tagsTwo = mapTagsWithIds(item.ImageTwo.tags, item.ImageTwo.id);
    const tagsThree = mapTagsWithIds(item.ImageThree.tags, item.ImageThree.id);

    const allTags = [...tagsOne, ...tagsTwo, ...tagsThree];

    const tagCount = allTags.reduce((acc, tag) => {
        if (!acc[tag.tagName]) {
            acc[tag.tagName] = { count: 0, ids: [] };
        }
        acc[tag.tagName].count += 1;
        acc[tag.tagName].ids.push(tag.imageId);
        return acc;
    }, {});

    const commonTags = Object.keys(tagCount)
        .filter(tag => tagCount[tag].count > 1)
        .map(tag => ({ tagName: tag, ...tagCount[tag] }));


    const handleMouseEnter = (tag) => {
        setHoveredImages(tag.ids);
    };

    const handleMouseLeave = () => {
        setHoveredImages([]);
    };

    return (
    <div className="mb-4 xl:mb-16">

        <h2>{item.createdAt.split('T')[0]}</h2>
        <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/3 p-2 md:px-4 md:py-0"
                 style={{
                    border: hoveredImages.includes(images[0]) ? '2px solid red' : 'none'
                }}
            >
                <Image data={item.ImageOne} />
            </div>
            <div className="w-full md:w-1/3 p-2 md:px-4 md:py-0"
                 style={{
                    border: hoveredImages.includes(images[1]) ? '2px solid red' : 'none'
                }}
            >
                <Image data={item.ImageTwo} />
            </div>
            <div className="w-full md:w-1/3 p-2 md:px-4 md:py-0"
                style={{
                    border: hoveredImages.includes(images[2]) ? '2px solid red' : 'none'
                }}
            >
                <Image data={item.ImageThree} />
            </div>
        </div>
        <p>{item.text}</p>
        <p>--</p>
        <div>
            { commonTags.length > 0 ? (
                <>
                    <p className="bold">Common Tags:</p>
                    <ul>
                        {commonTags.map((tag, index) => (
                            <li key={index}
                                onMouseEnter={() => handleMouseEnter(tag)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {tag.tagName}
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <p>No common tags</p>
            )}
        </div>
        <p>--</p>
        <div>
            {item.thread_tags.length > 0 ? (
                <>
                    <p className="bold">Text Analysis Tags:</p>
                    <ul>
                        {item.thread_tags.map((tag, index) => (
                            <li key={index}
                            >
                                {tag.tagName}
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <p> No text analysis tags. </p>
            )}
        </div>
        <hr />
        </div>
    )
}

export default Thread;